import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { page } from "components/page";
import navigate from "lib/navigate";

const getFaqList = gql`
  query getFaqList {
    result: getFaqList {
      list {
        sn
        quest
        answer
        creTime
        updTime
        urls
        attachs {
          showName
          attachName
        }
      }
      classes {
        sn
        className
        faqs {
          sn
          quest
        }
      }
    }
  }
`;

@withI18next(["common"])
@page
class FaqV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      listContent: {},
    };
  }

  //componentDidMount() {
  //  this.getList(this.props)
  //}

  @autobind
  showDetail(e) {
    e.preventDefault();
    var url = "/faqDetail?sn=" + e.target.dataset.sn;
    console.log(url);
    console.log(e.target.name);
    console.log(e.target.value);
    console.log(e.target.dataset.sn);
    navigate(url);
  }

  render() {
    let { t } = this.props;
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("jumper.common.faq")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>
              <div className="detail_block">
                <ApolloProvider client={client.jumperrwdClient}>
                  <Query query={getFaqList} variables={{}}>
                    {({ loading, data, refetch, error }) => {
                      if (error) return "";
                      if (loading) return "Loading...";

                      if (data !== null && data !== undefined) {
                        return (
                          <>
                            {data.result.list !== null &&
                              data.result.list !== undefined &&
                              data.result.list.map((item) => {
                                let quest =
                                  t("auto.ws_question.quest." + item.sn) !==
                                  "auto.ws_question.quest." + item.sn
                                    ? t("auto.ws_question.quest." + item.sn)
                                    : item.quest;
                                return (
                                  <li>
                                    <a
                                      href="javascript:;"
                                      tabIndex="0"
                                      data-sn={item.sn}
                                      name="test"
                                      onClick={this.showDetail.bind(this)}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          this.showDetail(e);
                                        }
                                      }}>
                                      {quest}
                                    </a>
                                  </li>
                                );
                              })}

                            {data.result.classes !== null &&
                              data.result.classes !== undefined &&
                              data.result.classes.map((item) => {
                                let className =
                                  t(
                                    "auto.ws_question_class_dir.class_name." +
                                      item.sn
                                  ) !==
                                  "auto.ws_question_class_dir.class_name." +
                                    item.sn
                                    ? t(
                                        "auto.ws_question_class_dir.class_name." +
                                          item.sn
                                      )
                                    : item.className;
                                return (
                                  <div className="question_block col-6-6">
                                    <h3>
                                      <a href="javascript:;">{className}</a>
                                    </h3>
                                    <ul className="container">
                                      {item.faqs !== null &&
                                        item.faqs !== undefined &&
                                        item.faqs.map((item2) => {
                                          let quest =
                                            t(
                                              "auto.ws_question.quest." +
                                                item2.sn
                                            ) !==
                                            "auto.ws_question.quest." + item2.sn
                                              ? t(
                                                  "auto.ws_question.quest." +
                                                    item2.sn
                                                )
                                              : item2.quest;
                                          return (
                                            <li className="col">
                                              <a
                                                href="javascript:;"
                                                tabIndex="0"
                                                data-sn={item2.sn}
                                                name="test"
                                                onClick={this.showDetail.bind(
                                                  this
                                                )}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    this.showDetail(e);
                                                  }
                                                }}>
                                                {quest}
                                              </a>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                );
                              })}
                          </>
                        );
                      } else {
                        return "";
                      }
                    }}
                  </Query>
                </ApolloProvider>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default FaqV2;
